import React, { useState, useEffect, useRef } from 'react';
import { string, shape } from 'prop-types';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import { useIntl } from '../../../../util/reactIntl';
import { ListingCard } from '../../../../components';
import { createInstance } from 'sharetribe-flex-sdk';
import { useLandingPage } from '../../../../context/LandingPageContext';

import scribble3 from '../../../../assets/topLeftScribble.png';
import scribble4 from '../../../../assets/bottomRightScribble.png';
import michaelWinking from '../../../../assets/mic-wink.gif';

import css from './SectionLandingHero.module.css';

const SectionLandingHero = props => {
  const { sectionId, className } = props;
  const intl = useIntl();
  const { listings = { data: [] }, listingsResponse } = useLandingPage();

  // Make sure listings.data is always an array
  const listingsArray = Array.isArray(listings.data) ? listings.data : [];

  // Estados
  const [studioType, setStudioType] = useState('Recording');
  const [key, setKey] = useState(0);
  const currentIndexRef = useRef(0);
  const types = ['Recording', 'Mixing', 'Mastering', 'Production', 'Rehearsal'];

  // Studio type animation
  useEffect(() => {
    const interval = setInterval(() => {
      currentIndexRef.current = (currentIndexRef.current + 1) % types.length;
      setStudioType(types[currentIndexRef.current]);
      setKey(prev => prev + 1);
    }, 2000);
    
    return () => clearInterval(interval);
  }, []);

  const formatLocation = (address) => {
    if (!address) return '';
    
    // Split the address into parts
    const parts = address.split(',').map(part => part.trim());
    
    // If there's only one part (e.g., "Erie" or "Fort Worth"), return that
    if (parts.length === 1) return parts[0];
    
    // If there are multiple parts, try to get the city
    // Ignore street numbers and specific street names
    const cityPart = parts.find(part => {
      // Ignore parts that start with numbers or contain "Street", "Avenue", etc.
      return !part.match(/^\d/) && !part.match(/Street|Avenue|Road|Lane|Drive/i);
    });

    return cityPart || parts[0]; // If we don't find a clear city, use the first part
  };

  return (
    <div className={classNames(css.root, className)} id={sectionId}>
      <>
        <div className={css.heroContent}>
          <h1 className={css.title}>Make Music in Miami.</h1>
          <h3 className={css.studioTypeHeading}>
            Find a <span key={key} className={css.animatedText}>{studioType}</span> Studio
          </h3>
          <div className={css.michaelCoolIconRight}>
            <img 
              src={`${michaelWinking}?${Date.now()}`}
              alt="Michael Winking Right" 
              style={{ width: '100%', height: '100%' }}
            />
          </div>
        </div>

        <div className={css.listingsGridContainer}>
          <img src={scribble3} alt="Top Left Scribble" className={css.topLeftScribble} />
          <div className={css.listingsGrid}>
            {listingsArray.map(listing => {
              const imageUrl = listing.relationships?.images?.data[0]?.id?.uuid;
              const includedImage = listings?.included?.find(
                item => item.type === 'image' && item.id.uuid === imageUrl
              );
              
              const priceInDollars = listing.attributes.price.amount / 100;
              const location = formatLocation(listing.attributes.publicData?.location?.address);
              
              return (
                <Link 
                  key={listing.id.uuid} 
                  to={`/l/${listing.id.uuid}`}
                  className={css.listingCard}
                >
                  <div className={css.imageWrapper}>
                    <img 
                      src={includedImage?.attributes?.variants?.default?.url} 
                      alt={listing.attributes.title}
                      className={css.listingImage}
                    />
                    <div className={css.pillsContainer}>
                      {/* {listing.attributes.publicData?.featuredStudio === 'yes' && (
                        <span className={css.featuredPill}>Featured</span>
                      )} */}
                      <span className={css.pricePill}>
                        ${priceInDollars}/hr
                      </span>
                    </div>
                  </div>
                  <div className={css.listingInfo}>
                    <h3 className={css.listingTitle}>
                      {listing.attributes.title}
                    </h3>
                    <p className={css.listingLocation}>
                      {location}
                    </p>
                  </div>
                </Link>
              );
            })}
          </div>
          <img src={scribble4} alt="Bottom Right Scribble" className={css.bottomRightScribble} />
        </div>

        <div className={css.seeAllButtonContainer}>
          <Link 
            to="/s?bounds=26.48332086%2C-79.7522146%2C25.37675036%2C-80.74098413&mapSearch=true" 
            className={classNames(css.ctaButtonOutlined, css.seeAllButton)}
          >
            See All Studios
          </Link>
        </div>
      </>
    </div>
  );
};

SectionLandingHero.defaultProps = {
  className: null,
};

SectionLandingHero.propTypes = {
  sectionId: string.isRequired,
  className: string,
};

export default SectionLandingHero; 