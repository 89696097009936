import React, { useState, useEffect, useRef } from 'react';
import { string } from 'prop-types';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import { useIntl } from '../../../../util/reactIntl';
import { createInstance } from 'sharetribe-flex-sdk';
import michaelCool from '../../../../assets/michael-cool.png';
import { useLandingPage } from '../../../../context/LandingPageContext';

import css from './SectionLandingCommunity.module.css';

const SectionLandingCommunity = props => {
  const { sectionId, className } = props;
  const [users, setUsers] = useState([]);
  const [loadingUsers, setLoadingUsers] = useState(false);
  const [usersResponse, setUsersResponse] = useState(null);
  const [position, setPosition] = useState(0);
  const containerRef = useRef(null);
  const [isMobile, setIsMobile] = useState(false);
  const isComponentMounted = useRef(true);

  useEffect(() => {
    return () => {
      isComponentMounted.current = false;
    };
  }, []);

  useEffect(() => {
    const fetchUsers = async () => {
      if (!isComponentMounted.current) return;
      setLoadingUsers(true);
      try {
        const sdk = createInstance({
          clientId: process.env.REACT_APP_SHARETRIBE_SDK_CLIENT_ID
        });

        const response = await sdk.listings.query({
          pub_availability: 'available',
          include: ['author', 'author.profileImage'],
          'fields.user': [
            'profile.displayName',
            'profile.bio',
            'profile.abbreviatedName'
          ],
          per_page: 100,
          sort: '-createdAt'
        });

        if (!isComponentMounted.current) return;
        setUsersResponse(response.data);

        // Filter unique users that have profileImage
        const uniqueAuthors = [...new Map(
          response.data.included
            ?.filter(included => included.type === 'user')
            .filter(user => user.relationships?.profileImage?.data)
            .map(user => [user.id.uuid, user])
        ).values()];

        if (!isComponentMounted.current) return;
        setUsers(uniqueAuthors);
      } catch (error) {
        console.error('Error fetching studio owners:', error);
      } finally {
        if (isComponentMounted.current) {
          setLoadingUsers(false);
        }
      }
    };

    fetchUsers();
  }, []);

  useEffect(() => {
    if (typeof window !== 'undefined') {
      setIsMobile(window.innerWidth <= 768);

      const handleResize = () => {
        setIsMobile(window.innerWidth <= 768);
      };

      window.addEventListener('resize', handleResize);
      return () => window.removeEventListener('resize', handleResize);
    }
  }, []);

  // Create a duplicated array for the infinite effect
  const displayUsers = [...users, ...users, ...users];

  useEffect(() => {
    if (users.length === 0) return;

    let animationFrameId;
    const animate = () => {
      if (!isComponentMounted.current) return;
      
      setPosition(prev => {
        const baseSpeed = 0.02;
        const screenWidth = typeof window !== 'undefined' ? window.innerWidth : 375;
        const normalizedSpeed = baseSpeed * (375 / screenWidth);
        
        const newPosition = prev - normalizedSpeed;
        
        if (newPosition <= -100) {
          return -33.33;
        }
        
        return newPosition;
      });

      animationFrameId = requestAnimationFrame(animate);
    };

    animationFrameId = requestAnimationFrame(animate);

    return () => {
      if (animationFrameId) {
        cancelAnimationFrame(animationFrameId);
      }
    };
  }, [users.length]);

  return (
    <div className={classNames(css.root, className)} id={sectionId}>
      <div className={css.communitySection}>
        <h2 className={css.communityHeading}>Owners</h2>
        <p className={css.communityDescription}>
          Profiles behind the studios on our platform
        </p>
        
        <div className={css.communityUsersContainer}>
          <div 
            ref={containerRef}
            className={css.communityUsers}
            style={{
              transform: `translateX(${position}%)`,
              transition: 'transform 0.005s linear'
            }}
          >
            {displayUsers.map((user, index) => {
              const displayName = user.attributes?.profile?.displayName;
              const userBio = user.attributes?.profile?.bio;
              const userId = user.id?.uuid;
              
              // Get the image from the profileImage
              let imageUrl = michaelCool;
              const profileImageId = user.relationships?.profileImage?.data?.id?.uuid;
              
              if (profileImageId && usersResponse?.included) {
                const imageData = usersResponse.included?.find(
                  inc => inc.type === 'image' && inc.id.uuid === profileImageId
                );
                if (imageData?.attributes?.variants?.default?.url) {
                  imageUrl = imageData.attributes.variants.default.url;
                }
              }
              
              return (
                <div key={`${userId}-${index}`} className={css.userCard}>
                  <Link to={`/u/${userId}`} className={css.avatarContainer}>
                    <div className={css.avatarWrapper}>
                      <img 
                        src={imageUrl}
                        alt={displayName}
                        className={css.avatar}
                        onError={(e) => {
                          e.target.src = michaelCool;
                        }}
                      />
                      {userBio && (
                        <div className={css.bioOverlay}>
                          <p className={css.bioQuote}>"{userBio}"</p>
                        </div>
                      )}
                    </div>
                    <h3 className={css.userName}>{displayName}</h3>
                  </Link>
                </div>
              );
            })}
          </div>
        </div>
        <Link to="/l/draft/00000000-0000-0000-0000-000000000000/new/details" className={css.listYourStudio}>
          Request to List Your Studio
        </Link>
      </div>
    </div>
  );
};

SectionLandingCommunity.defaultProps = {
  className: null,
};

SectionLandingCommunity.propTypes = {
  sectionId: string.isRequired,
  className: string,
};

export default SectionLandingCommunity; 