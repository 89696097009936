import React from "react"
import { string } from "prop-types"
import classNames from "classnames"
import { useIntl } from "../../../../util/reactIntl"
import css from "./SectionLandingMarketplaceExplainer.module.css"
import { Link } from "react-router-dom"
import scribble1 from '../../../../assets/scribble1.png';
import scribble2 from '../../../../assets/scribble2.png';
import stuviLogo from '../../../../assets/stuvi-logo-white.svg';

const SectionLandingMarketplaceExplainer = (props) => {
  const { sectionId, className } = props;
  const intl = useIntl();

  return (
    <div className={classNames(css.root, className)} id={sectionId}>
      <div className={css.explainerSection}>
        <h2 className={css.explainerHeading}>
          How Stuvi Works
        </h2>

        <div className={css.contentGrid}>
          <div className={css.column}>
            <div className={css.videoContainerSquare}>
              <div className={css.videoWrapperSquare}>
                <video 
                  autoPlay 
                  loop 
                  muted 
                  playsInline 
                  className={css.video}
                >
                  <source src="https://stuviassets.s3.amazonaws.com/gif-for-artists.mp4" type="video/mp4" />
                </video>
              </div>
            </div>
            <div className={css.description}>
              <h3 className={css.descriptionTitle}>Artists</h3>
              <p className={css.descriptionText}>Want to make music or record a song</p>
            </div>
          </div>

          <img src={scribble1} alt="" className={css.scribbleLeft} />

          <div className={css.column}>
            <div className={css.videoContainerStuvi}>
              <div className={css.videoWrapperStuvi}>
                <video 
                  autoPlay 
                  loop 
                  muted 
                  playsInline 
                  className={css.video}
                >
                  <source src="https://stuviassets.s3.amazonaws.com/Hush+Maeve+Jules+Blondie.mp4" type="video/mp4" />
                </video>
              </div>
            </div>
            <div className={css.description}>
              <div className={css.stuviLogoContainer}>
                <img src={stuviLogo} alt="Stuvi" className={css.stuviLogo} />
              </div>
              <p className={css.descriptionText}>Helps find, book and pay for studio space</p>
            </div>
          </div>

          <img src={scribble2} alt="" className={css.scribbleRight} />

          <div className={css.column}>
            <div className={css.videoContainerSquare}>
              <div className={css.videoWrapperSquare}>
                <video 
                  autoPlay 
                  loop 
                  muted 
                  playsInline 
                  className={css.video}
                >
                  <source src="https://stuviassets.s3.amazonaws.com/gif-for-studios.mp4" type="video/mp4" />
                </video>
              </div>
            </div>
            <div className={css.description}>
              <h3 className={css.descriptionTitle}>Studios</h3>
              <p className={css.descriptionText}>Get booked and paid after the session</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

SectionLandingMarketplaceExplainer.defaultProps = {
  className: null,
};

SectionLandingMarketplaceExplainer.propTypes = {
  sectionId: string.isRequired,
  className: string,
};

export default SectionLandingMarketplaceExplainer; 