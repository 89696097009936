import React, { useState, useCallback, useEffect } from 'react';
import { string, object } from 'prop-types';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import css from './SectionLandingCTA.module.css';
import micWink from '../../../../assets/michael-cool.png';

const SectionLandingCTA = props => {
  const { sectionId, className, currentUser } = props;
  const [showCopied, setShowCopied] = useState(false);
  const PROMO_CODE = 'MAKE.MUSIC';

  const handleCopyCode = useCallback(async () => {
    try {
      await navigator.clipboard.writeText(PROMO_CODE);
      setShowCopied(true);
    } catch (err) {
      console.error('Failed to copy text: ', err);
    }
  }, [PROMO_CODE]);

  useEffect(() => {
    let timeoutId;
    
    if (showCopied) {
      timeoutId = setTimeout(() => {
        setShowCopied(false);
      }, 2000);
    }

    return () => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
    };
  }, [showCopied]);

  const ctaLink = currentUser 
    ? "/s?bounds=26.75684164%2C-79.45406184%2C25.02483706%2C-81.01137385&mapSearch=true"
    : "/signup";

  return (
    <div className={classNames(css.root, className)} id={sectionId}>
      <div className={css.ctaSection}>
        <div className={css.ctaCard}>
          <h2 className={css.ctaHeading}>Not convinced yet?</h2>
          <p className={css.ctaPromo}>
            Use code{' '}
            <span 
              className={css.promoCode} 
              onClick={handleCopyCode}
              role="button"
              tabIndex={0}
              data-tooltip={showCopied ? 'Copied!' : 'Click to copy'}
            >
              {PROMO_CODE}
            </span>
            {' '}for a 5% discount on your first booking.
          </p>
          <Link 
            to={ctaLink}
            className={css.ctaButton}
          >
            {currentUser ? 'Browse Studios' : 'Start Booking Today'}
          </Link>
        </div>
        <img 
          src={micWink} 
          alt="Mic Wink" 
          className={css.micWink}
        />
      </div>
    </div>
  );
};

SectionLandingCTA.defaultProps = {
  className: null,
  currentUser: null,
};

SectionLandingCTA.propTypes = {
  sectionId: string.isRequired,
  className: string,
  currentUser: object,
};

const mapStateToProps = state => ({
  currentUser: state.user.currentUser,
});

export default connect(mapStateToProps)(SectionLandingCTA); 