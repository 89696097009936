import React, { useState, useEffect } from 'react';
import { string } from 'prop-types';
import classNames from 'classnames';
import { useIntl } from '../../../../util/reactIntl';
import css from './SectionLandingTestimonials.module.css';
import { Link } from 'react-router-dom';
import { useLandingPage } from '../../../../context/LandingPageContext';
import LoadingSpinner from '../../../../components/LoadingSpinner/LoadingSpinner';
import michaelSad from '../../../../assets/michael-sad.png';

const ArrowLeft = () => (
  <svg viewBox="0 0 24 24" fill="currentColor">
    <path d="M15.41 7.41L14 6l-6 6 6 6 1.41-1.41L10.83 12z"/>
  </svg>
);

const ArrowRight = () => (
  <svg viewBox="0 0 24 24" fill="currentColor">
    <path d="M10 6L8.59 7.41 13.17 12l-4.58 4.59L10 18l6-6z"/>
  </svg>
);

const SectionLandingTestimonials = props => {
  const { sectionId, className } = props;
  const { reviews, isLoading, error } = useLandingPage();
  const [currentReview, setCurrentReview] = useState(0);
  const intl = useIntl();


  const renderStars = (rating) => {
    return '★'.repeat(rating) + '☆'.repeat(5 - rating);
  };

  const handlePrevious = () => {
    setCurrentReview(current => 
      current === 0 ? reviews.length - 1 : current - 1
    );
  };

  const handleNext = () => {
    setCurrentReview(current => 
      current === reviews.length - 1 ? 0 : current + 1
    );
  };

  const content = () => {
    if (isLoading) {
      return <LoadingSpinner />;
    }

    if (error) {
      return (
        <div className={css.errorContainer}>
          <img src={michaelSad} alt="Error" className={css.errorIcon} />
          <h2 className={css.errorTitle}>Oops! Something went wrong</h2>
          <p className={css.errorText}>We couldn't load the reviews right now</p>
        </div>
      );
    }

    if (!reviews || reviews.length === 0) {
      return (
        <div className={css.errorContainer}>
          <h2 className={css.errorTitle}>No reviews yet</h2>
          <p className={css.errorText}>Be the first to leave a review!</p>
        </div>
      );
    }

    return (
      <>
        <h2 className={css.testimonialHeading}>
          {intl.formatMessage({ id: 'SectionLandingTestimonials.title' })}
        </h2>
        
        <div className={css.testimonialCarousel}>
          {reviews.map((review, index) => (
            <div 
              key={review.id}
              className={classNames(
                css.testimonialCard,
                index === currentReview ? css.active : ''
              )}
            >
              <div className={css.rating}>
                {renderStars(review.rating)}
              </div>
              <p className={css.testimonialText}>"{review.text}"</p>
              <div className={css.testimonialAuthor}>
                <strong>{review.author} @ {review.listing}</strong>
              </div>
            </div>
          ))}
        </div>

        <div className={css.navigation}>
          <div className={css.arrows}>
            <button 
              className={css.arrow} 
              onClick={handlePrevious}
              aria-label="Previous review"
            >
              <ArrowLeft />
            </button>
            <button 
              className={css.arrow} 
              onClick={handleNext}
              aria-label="Next review"
            >
              <ArrowRight />
            </button>
          </div>
          <div className={css.indicators}>
            {reviews.map((_, index) => (
              <button
                key={index}
                className={classNames(
                  css.indicator,
                  index === currentReview ? css.activeIndicator : ''
                )}
                onClick={() => setCurrentReview(index)}
                aria-label={`View testimonial ${index + 1}`}
              />
            ))}
          </div>
        </div>
      </>
    );
  };

  return (
    <div className={classNames(css.root, className)} id={sectionId}>
      <div className={css.testimonialSection}>
        {content()}
      </div>
    </div>
  );
};

SectionLandingTestimonials.defaultProps = {
  className: null,
};

SectionLandingTestimonials.propTypes = {
  sectionId: string.isRequired,
  className: string,
};

export default SectionLandingTestimonials; 