import React from 'react';
import underline from '../../../../assets/underline.png';
import css from './SectionDivider.module.css';

const SectionDivider = () => (
  <div className={css.dividerContainer}>
    <img src={underline} alt="" className={css.dividerImage} />
  </div>
);

export default SectionDivider; 