import React, { useMemo } from 'react';
import { bool, object } from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import loadable from '@loadable/component';

import { 
  SectionLandingHero, 
  SectionLandingCommunity, 
  SectionLandingTestimonials,
  SectionLandingMarketplaceExplainer,
  SectionDivider,
  SectionLandingCTA 
} from '../PageBuilder/SectionBuilder';
import FallbackPage from './FallbackPage';

const PageBuilder = loadable(() =>
  import(/* webpackChunkName: "PageBuilder" */ '../PageBuilder/PageBuilder')
);

// Memoize the custom page data to prevenir re-renders innecesarios
const LandingPageComponent = props => {
  const { pageAssetsData, inProgress, error } = props;

  const customPageData = useMemo(() => ({
    sections: [
      {
        sectionId: 'landing-hero',
        sectionType: 'landingHero',
      },
      {
        sectionId: 'divider-1',
        sectionType: 'divider',
      },
      {
        sectionId: 'marketplace-explainer',
        sectionType: 'marketplaceExplainer',
      },
      {
        sectionId: 'divider-2',
        sectionType: 'divider',
      },
      {
        sectionId: 'landing-community',
        sectionType: 'landingCommunity',
      },
      {
        sectionId: 'divider-3',
        sectionType: 'divider',
      },
      {
        sectionId: 'landing-testimonials',
        sectionType: 'landingTestimonials',
      },
      {
        sectionId: 'divider-4',
        sectionType: 'divider',
      },
      {
        sectionId: 'landing-cta',
        sectionType: 'landingCTA',
      },
    ],
  }), []); // Empty dependency array ya que nunca cambia

  const sectionComponents = useMemo(() => ({
    landingHero: { component: SectionLandingHero },
    landingCommunity: { component: SectionLandingCommunity },
    landingTestimonials: { component: SectionLandingTestimonials },
    divider: { component: SectionDivider },
    marketplaceExplainer: { component: SectionLandingMarketplaceExplainer },
    landingCTA: { component: SectionLandingCTA },
  }), []); // Empty dependency array ya que nunca cambia

  return (
    <PageBuilder
      pageAssetsData={customPageData}
      options={{
        sectionComponents,
      }}
      inProgress={inProgress}
      error={error}
      fallbackPage={<FallbackPage error={error} />}
    />
  );
};

// Wrap the entire app with LandingPageProvider
const LandingPage = props => {
  return <LandingPageComponent {...props} />;
};

LandingPage.defaultProps = {
  error: null,
  pageAssetsData: null,
};

LandingPage.propTypes = {
  error: object,
  pageAssetsData: object,
  inProgress: bool.isRequired,
};

const mapStateToProps = state => {
  const { pageAssetsData, inProgress, error } = state.hostedAssets || {};
  return { pageAssetsData, inProgress, error };
};

export default compose(
  connect(mapStateToProps),
  withRouter
)(LandingPage);
